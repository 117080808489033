<template>
  <!--begin::Container-->
  <div v-if="$apollo.data.returnData" class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container">
        <div class="text-lg-right">
          <v-btn
            elevation="9"
            rounded
            color="grey"
            @click="
              $router.push(
                '/market/order-detail/' + returnData.order.identifier
              )
            "
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="container">
            <div>
              Sold To:
              {{ returnData.order.masterOrder.buyerCompany.company_name }}
            </div>
            <div class="row ">
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Billing Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5> -->

                    <p class="mb-2">
                      {{ returnData.order.billToAddress.company_name }}<br />
                      {{ returnData.order.billToAddress.address_line1 }}<br />
                      {{ returnData.order.billToAddress.address_line2 }}<br />
                      {{ returnData.order.billToAddress.city }}
                      {{ returnData.order.billToAddress.state }}
                      {{ returnData.order.billToAddress.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      {{ returnData.order.billToAddress.phone }}
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Shipping Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5> -->

                    <p class="mb-2">
                      {{ returnData.order.shipToAddress.company_name }}<br />
                      {{ returnData.order.shipToAddress.address_line1 }}<br />
                      {{ returnData.order.shipToAddress.address_line2 }}<br />
                      {{ returnData.order.shipToAddress.city }}
                      {{ returnData.order.shipToAddress.state }}
                      {{ returnData.order.shipToAddress.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      {{ returnData.order.shipToAddress.phone }}
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->

              <div class="col-lg-4">
                <v-card flat>
                  <ul class="list-unstyled mb-0">
                    <li>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Purchase Order #:</span
                        >
                        {{ returnData.order.po }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Payment Type:</span
                        >
                        NET TERMS
                      </p>
                    </li>
                  </ul>
                </v-card>
              </div>
              <!-- end col -->
            </div>

            <div class="row  ">
              <div class="col-lg-12">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header card-header-tabs-line">
                    <div class="card-title">
                      <h3 class="card-label">
                        Items from Return #{{ $route.params.id }}
                      </h3>
                    </div>
                    <div class="card-toolbar"></div>
                  </div>

                  <div class="card-body">
                    <!--begin: Datatable-->
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">Line ID</th>
                          <th style="width:280px;">SKU</th>
                          <th>Thumb</th>
                          <th style="width:280px;">Item</th>
                          <th style="width:100px;">Qty Returned</th>
                          <th class="text-left">Price</th>
                          <th class="text-left">Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, idx) in returnData.returnItems"
                          :key="idx"
                        >
                          <td>{{ item.invoiceItem.orderItem.line }}</td>
                          <td>{{ item.invoiceItem.sku }}</td>
                          <td>
                            <v-img
                              class="d-flex align-self-center mr-3 rounded-circle"
                              :src="
                                item.url != null
                                  ? baseurl + item.url
                                  : '/media/rollerskate.png'
                              "
                              alt="Generic placeholder image"
                              width="50"
                              height="50"
                            >
                            </v-img>
                          </td>
                          <td>
                            {{ item.invoiceItem.productName }}
                            <span v-if="item.invoiceItem.name != 'default'"
                              >({{ item.invoiceItem.name }})</span
                            >
                          </td>
                          <td>
                            {{ item.quantity }}
                          </td>
                          <td>
                            {{ item.unitPrice | dollar_format }}
                          </td>
                          <td>
                            {{ item.lineTotal | dollar_format }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" style="text-align: right;">
                            Subtotal
                          </td>
                          <td>
                            {{
                              subTotal(returnData.returnItems) | dollar_format
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" style="text-align: right;">
                            Shipping refund
                          </td>
                          <td>
                            {{ returnData.shippingRefund | dollar_format }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" style="text-align: right;">
                            Restock fee
                          </td>
                          <td>
                            {{ returnData.restockFee | dollar_format }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" style="text-align: right;">Total</td>
                          <td>
                            {{ returnData.total | dollar_format }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                </v-card>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import GetReturn from "@/graphql/queries/GetReturn.gql";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    seller_company_name: "",
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    dialog: false,
    orderTab: "Information",
    selected: 2,
    model: "tab-2",
    orders: [],
    main_order_date: "",
    billto: [],
    shipto: [],
  }),
  apollo: {
    returnData: {
      query: GetReturn,
      variables() {
        return { id: this.$route.params.id };
      },
    },
  },
  methods: {
    ...mapActions(["getCompanyInfo"]),
    subTotal(items) {
      return items.reduce((a, c) => a + c.invoiceItem.unitPrice, 0);
    },
  },
};
</script>
